$bodywidth: 1000px;
$sidebarwidth: 200px;
$navwidth: 1200px;

@media only screen and (min-width: 1200px) {
    nav {
        .links {
            max-width: $navwidth;
        }
    }

    .content-container {
        .content {
            max-width: $bodywidth;
        }

        .sidebar {
            display: initial;
            width: $sidebarwidth;
        }
    }
}