$navheight: 32px;
$bodywidth: 100%;
$sidebarwidth: 200px;
$thumb-width: 128px;

$black: #222;
$darkgrey: #555;
$grey: #888;
$lightgrey: #bbb;
$white: #ddd;

$monospace: 'Courier New', Courier, monospace;

* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
    background: $lightgrey;
}

body {
    padding: 0;
    margin: 0;
    font-family: roboto, sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: $monospace;
    margin: 0;
}

h2 {
    color: $darkgrey;
    font-size: 20px;
}

ul {
    padding-left: 0;

    li {
        list-style-type: none;
    }
}

nav {
    text-align: center;
    height: $navheight;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    .links {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-items: start;
        -ms-flex-align: start;
        align-items: start;

        max-width: $bodywidth;
        background-color: $darkgrey;
        height: 32px;

        a {
            height: 28px;
            line-height: 28px;
            border-radius: 0 0 8px 8px;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            margin: 0 8px;
            background-color: $grey;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 4px;
            font-family: $monospace;
            font-weight: 900;
            

            &.current {
                background-color: $white;
                color: $black;
            }
        }
    }
}

.content-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    width: 100%;
    height: calc(100% - $navheight);

    .content {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;

        max-width: $bodywidth;
        background-color: $white;
        color: $black;
        padding: 20px;
        overflow-x: hidden;
        overflow-y: auto;

        section {
            margin-bottom: 64px;

            &.edu, &.work {
                li {
                    margin: 16px 0;
                    
                    .title, .subtitle, .position, .timeline {
                        font-family: $monospace;
                    }

                    .title {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .subtitle, .position {
                        font-style: italic;
                    }

                    .timeline {
                        font-style: italic;
                        color: $darkgrey;
                    }
                }

                p {
                    margin: 16px 0;
                }
            }
        }

        img {
            &.thumb {
                max-width: $thumb-width;
            }

            &.circular {
                border-radius: 50%;
            }

            &.rounded {
                border-radius: 8px;
            }
        }

        .main-links {
            li {
                border: 2px solid $black;
                background-color: $lightgrey;
                margin: 16px 32px;
                padding: 4px;
            }
        }

        .flex-between {
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

        .flex, .flex-around, .flex-between {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            * {
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
            }

            .stretch {
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
            }
        }

        .padded {
            padding: 24px;
        }
    }

    .sidebar {
        display: none;
        width: $sidebarwidth;
        padding: 16px;
        background-color: $white;

        h2 {
            margin-top: 16px;
        }

        ul.posts, ul.links {
            li {
                margin: 16px 0;
            }
        }
    }
}
