section {
    &.post {
        p {
            &.date {
                font-style: italic;
            }

            img {
                width: 80%;
                // Center using margin-left, and add some whitespace above and below
                margin: 32px 0 32px 10%;
            }
        }

        ul {
            // Posts should be able to use normal list styles
            padding-left: 40px;
    
            li {
                list-style-type:disc;
            }
        }
    }
}