$navheight: 32px;
$bodywidth: 800px;
$thumb-width: 256px;

@media only screen and (min-width: 768px) {
    nav {
        .links {
            max-width: $bodywidth;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            a {
                height: 24px;
                line-height: 24px;
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
                border-radius: 8px;
                padding: 0 64px;
                letter-spacing: 4px;
            }
        }
    }

    .content-container {
        .content {
            max-width: $bodywidth;

            section {
                .flex-between {
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                }
            }

            img {
                &.thumb {
                    max-width: $thumb-width;
                    margin: 0 32px;
                }
            }
        }
    }
}